import { Box, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import React, { Fragment } from "react";
import { ChainInfoDto } from "../../../models/ApiSchema";
import Number from "../../Formatters/Number";
import Date from "../../Formatters/Date";
import PageHeader from "../../PageHeader/PageHeader";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { Alert } from "@material-ui/lab";

interface ChainInfoHealthProps {
  chainInfo: ChainInfoDto | null | undefined;
}

const ChainInfoHealth: React.FC<ChainInfoHealthProps> = ({chainInfo}) => {
  if (!chainInfo) return <Fragment></Fragment>;

  return (
    <Fragment>
      <Box my={2}>
        <Grid container direction="row" alignItems="center">
          {chainInfo.noAnimalProtectionRestrictions ? <CheckBoxIcon color="secondary" /> : <CheckBoxOutlineBlankIcon color="disabled" />}
          Tilalla ei ole eläintautilain nojalla annettuja rajoittavia määräyksiä.
        </Grid>
        <Grid container direction="row" alignItems="center">
          {chainInfo.noFoodSafetyIssuesDetected ? <CheckBoxIcon color="secondary" /> : <CheckBoxOutlineBlankIcon color="disabled" />}
          Kasvatusaikana ei ole todettu elintarviketurvallisuuteen vaikuttavia tauteja.
        </Grid>
        <Grid container direction="row" alignItems="center">
          {chainInfo.healthAnomaliesDetected ? <CheckBoxIcon color="secondary" /> : <CheckBoxOutlineBlankIcon color="disabled" />}
          Tuottajan / hoitajan havaitsemat poikkeamat lintujen terveydessä ja kunnossa, sekä muut huomiot. Kuvaus:
        </Grid>
        {chainInfo.healthAnomalies != null && chainInfo?.healthAnomalies !== ""
          ? <p>{chainInfo.healthAnomalies}</p>
          : (chainInfo.healthAnomaliesDetected ? <p><em>Kuvausta ei syötetty.</em></p> : <span></span>)}
      </Box>
      <PageHeader level="3" component="div">Eläinlääkärin / ruokaviraston / laboratorion toteamat taudit ja otetut näytteet</PageHeader>
      <Box my={2}>
        {chainInfo.diseases != null && chainInfo.diseases.length > 0 &&<TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell component="th" scope="column">Toteamispäivä</TableCell>
                <TableCell component="th" scope="column">Otetut näytteet</TableCell>
                <TableCell component="th" scope="column">Diagnoosi ruokavirasto</TableCell>
                <TableCell component="th" scope="column">Suullinen / ELL</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {chainInfo.diseases.map((d, i) => <TableRow key={i}>
                <TableCell><Date value={d.dateDetected} /></TableCell>
                <TableCell>{d.samplesTaken || "-"}</TableCell>
                <TableCell>{d.foodAgencyDiagnosis || "-"}</TableCell>
                <TableCell>{d.veterinarianDiagnosis || "-"}</TableCell>
              </TableRow>)}
            </TableBody>
          </Table>
        </TableContainer>}
        {(chainInfo.diseases == null || chainInfo.diseases.length === 0) &&
          <Alert severity="info">Ei todettuja tauteja tai otettuja näytteitä.</Alert>}
      </Box>
      {chainInfo.id != null && <Box my={2}>
        <strong>
          Viimeisin virallinen salmonellanäyte otettu{" "}
          <Date value={chainInfo.lastOfficialSalmonellaSampleDate} empty="[ei tiedossa]" />{" "}
          ja tulos: {chainInfo.lastOfficialSalmonellaSamplePositive == null ?
            "[ei tiedossa]" : (chainInfo.lastOfficialSalmonellaSamplePositive ? "POSITIIVINEN" : "NEGATIIVINEN")
        }</strong>
      </Box>}
      <PageHeader level="3" component="div">Lääkitys</PageHeader>
      <Box my={2}>
        <Grid container direction="row" alignItems="center">
          {chainInfo.noMedicationsUsed ? <CheckBoxIcon color="secondary" /> : <CheckBoxOutlineBlankIcon color="disabled" />}
          Lintuja ei ole lääkitty kasvatuskaudella
        </Grid>
      </Box>
      {chainInfo.medications != null && chainInfo.medications.length > 0 && <Box my={2}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell component="th" scope="column">Hoitojakso</TableCell>
                <TableCell component="th" scope="column">Lääkeaine</TableCell>
                <TableCell component="th" scope="column">Varoaika</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {chainInfo.medications?.map((m, i) => <TableRow key={i}>
                <TableCell><Date value={m.startDate} /> - <Date value={m.endDate} /></TableCell>
                <TableCell>{m.medicine || "-"}</TableCell>
                <TableCell><Number value={m.withholdingPeriod} empty="-" unit="vrk" digits={0} /></TableCell>
              </TableRow>)}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>}
    </Fragment>
  );
};

export default ChainInfoHealth;
